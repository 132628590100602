<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="4">
            <h3>{{ $store.state.subFeeStudentDetails.studentName }}</h3>
          </v-col>
          <v-col cols="4" align="center">
            <h3>القبول : {{ $store.state.subFeeStudentDetails.acceptedName }}</h3>
          </v-col>

          <v-col cols="3" align="center">
            <h3>المرحلة : {{ $store.state.subFeeStudentDetails.level | setLevel }}</h3>
          </v-col>

          <v-col cols="1" align="left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-arrow-left </v-icon>
                </v-btn>
              </template>
              <span>رجوع</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid v-if="true">
      <v-card>
        <v-row>
          <v-col cols="4">
            <AddSubFee :studentPay="$store.state.subFeeStudentDetails" @reloadTask="initialData()" style="padding: 10px; display: inline-block" />
          </v-col>
          <v-col cols="4" class="division-title">
            <h3 style="text-align: center">الوصول السابقة</h3>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-data-table :headers="headers" :items="allStudentFees" :items-per-page="15" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <h4 style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </h4>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="printVoucher(item)">
              <v-icon color="primary"> mdi-printer </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddSubFee from "../components/AddSubFee.vue";
export default {
  components: {
    AddSubFee,
  },
  data: () => ({
    haveDiscount: false,
    show: false,
    sectionCost: 0,
    overlay: false,
    showBillHistory: false,
    studentPayDetails: "",
    discountTypeId: "",
    discountTypes: [],
    allStudentFees: [],
    studentDiscountPercentage: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "voucherNumber" },
      { text: "تسديد عن", value: "typeName" },
      { text: "تاريخ الوصل", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      {
        text: " الملاحظات",
        value: "notes",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentPaymentsSubDetails/${self.$store.state.subFeeStudentDetails.collegeNumber}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.allStudentFees = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printVoucher() {
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(window);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width,initial-scale=1.0" />
            <link rel="icon" href="<%= BASE_URL %>favicon.ico" />
            <title><%= htmlWebpackPlugin.options.title %></title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css" />
          </head>
          <body>
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : 24469</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : 26/10/2021</h3>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 200px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-image__image v-image__image--contain" style="background-image: url('http://localhost:8080/img/logo.b48c030d.png'); background-position: center center"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">اجور الدراسة : IQD&nbsp;2,535,000</h3>
                    <h3 data-v-4a284cfa="" style="padding: 10px">نسية التحصيل الاجمالية : 0%</h3>
                  </div>
                </div>
                <div data-v-4a284cfa="">
                  <div data-v-4a284cfa="" class="row">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="">جامعة التراث / الحسابات</h3>
                      <h3 data-v-4a284cfa="">سند قبض</h3>
                      <div data-v-4a284cfa="">
                        <svg class="vue-barcode-element" width="290px" height="70px" x="0px" y="0px" viewBox="0 0 290 70" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0, 0)">
                          <rect x="0" y="0" width="290" height="70" style="fill: #ffffff"></rect>
                          <g transform="translate(10, 10)" style="fill: #000000">
                            <rect x="0" y="0" width="6" height="50"></rect>
                            <rect x="9" y="0" width="3" height="50"></rect>
                            <rect x="18" y="0" width="9" height="50"></rect>
                            <rect x="33" y="0" width="6" height="50"></rect>
                            <rect x="45" y="0" width="3" height="50"></rect>
                            <rect x="51" y="0" width="9" height="50"></rect>
                            <rect x="66" y="0" width="6" height="50"></rect>
                            <rect x="78" y="0" width="3" height="50"></rect>
                            <rect x="90" y="0" width="3" height="50"></rect>
                            <rect x="99" y="0" width="6" height="50"></rect>
                            <rect x="111" y="0" width="3" height="50"></rect>
                            <rect x="123" y="0" width="3" height="50"></rect>
                            <rect x="132" y="0" width="6" height="50"></rect>
                            <rect x="144" y="0" width="6" height="50"></rect>
                            <rect x="153" y="0" width="6" height="50"></rect>
                            <rect x="165" y="0" width="3" height="50"></rect>
                            <rect x="174" y="0" width="9" height="50"></rect>
                            <rect x="186" y="0" width="6" height="50"></rect>
                            <rect x="198" y="0" width="6" height="50"></rect>
                            <rect x="207" y="0" width="9" height="50"></rect>
                            <rect x="225" y="0" width="3" height="50"></rect>
                            <rect x="231" y="0" width="6" height="50"></rect>
                            <rect x="246" y="0" width="9" height="50"></rect>
                            <rect x="258" y="0" width="3" height="50"></rect>
                            <rect x="264" y="0" width="6" height="50"></rect>
                          </g>
                        </svg>
                        <div style="display: none">Show this if the rendering fails.</div>
                      </div>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">انا الموقع ادناه</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">I Certify</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <h3 data-v-4a284cfa="" style="padding: 10px">استلمت من الطالب /ة علي باسم محمد هادي / ذي الرقم الجامعي 1910100116</h3>
                  <h3 data-v-4a284cfa="" style="padding: 10px">( القسم : ادارة اعمال / المرحلة الرابعة / الدراسة : صباحي )</h3>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مبلغا وقدره</h3></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">IQD&nbsp;750,000</h3>
                      <h3 data-v-4a284cfa="" style="display: inline-block">سبعمائة خمسون ألف فقط لاغير</h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">وذلك عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">الدفعة الاولى</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">For</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">ملاحظات</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">لايوجد</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Notes</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4" style="padding-bottom: 80px">
                      <h3 data-v-4a284cfa="">اسم وتوقيع الموظف المختص</h3>
                      <h3 data-v-4a284cfa="">علا ماجد عبدالرزاق</h3>
                    </div>
                  </div>
                </div>
              </div>
              <button data-v-4a284cfa="" class="not-printable">print</button>
            </div>
            <!-- built files will be auto injected -->
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    saveData() {
      let self = this;
      if (self.studentDiscountPercentage && self.discountTypeId) {
        let data = {
          studentId: self.$store.state.subFeeStudentDetails.idStudent,
          studentName: self.$store.state.subFeeStudentDetails.studentName,
          level: self.$store.state.subFeeStudentDetails.studentLevel.level,
          sectionName: self.$store.state.subFeeStudentDetails.section.sectionName,
          studyType: self.$store.state.subFeeStudentDetails.studyType,
          acceptedTypeId: self.$store.state.subFeeStudentDetails.acceptedType.idAcceptedType,
          acceptedTypeName: self.$store.state.subFeeStudentDetails.acceptedType.acceptedName,
          discount: self.studentDiscountPercentage,
          discountTypeId: self.discountTypeId,
          createdBy: self.$store.state.userData.idUser,
        };
        self.$http.post(`${self.$store.state.apiUrlFees}/addStudentDiscount`, data).then((response) => {
          console.log(response);
          self.initialData();
        });
      } else {
        this.$toasted.error("الرجاء اختيار نوع التخفيض ونسبته ");
      }
    },
    studentFees(discountId) {
      let self = this;

      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentFeeDetailsSubType/${discountId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((feeResponse) => {
          self.allStudentFees = feeResponse.data;
          console.log(self.allStudentFees);
          self.showBillHistory = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
