import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import StudentFees from "../views/StudentFees.vue";
import Report from "../views/Report.vue";
import SectionCost from "../views/SectionCost.vue";
import Login from "../views/Login.vue";
import StudentDetails from "../views/StudentDetails.vue";
import SubFees from "../views/SubFees.vue";
import SubFeeDetails from "../views/SubFeeDetails.vue";
import Receipt from "../views/Receipt.vue";
import Expenses from "../views/Expenses.vue";
import FormApplication from "../views/FormApplication.vue";
import ExpensesType from "../views/ExpensesType.vue";
import ReceiptType from "../views/ReceiptType.vue";
import AcceptedType from "../views/AcceptedType.vue";
import PaymentReport from "../views/PaymentReport.vue";
import StudentTransfer from "../views/StudentTransfer.vue";
import Profile from "../views/Profile.vue";
import AddStudent from "../views/AddStudent.vue";
import PrintInvoice from "../views/PrintInvoice.vue";
import PrintExpenses from "../views/PrintExpenses.vue";
import PrintReceipt from "../views/PrintReceipt.vue";
import LevelOneStudents from "../views/LevelOneStudents.vue";
import StudentLevelOneDetails from "../views/StudentLevelOneDetails.vue";
import PaidStudent from "../views/PaidStudent.vue";
import CustomTable from "../views/CustomTable.vue";
import StudentDiscountReport from "../views/StudentDiscountReport.vue";
import SectionLevelStatistics from "../views/SectionLevelStatistics.vue";
import SectionCostForDates from "../views/SectionCostForDates.vue";
import AllStudentsUniversity from "../views/AllStudentsUniversity.vue";
import OldExpenses from "../views/OldExpenses.vue";
import OldFenex from "../views/OldFenex.vue";
import OldReceipt from "../views/OldReceipt.vue";
import ExpensesRequests from "../views/ExpensesRequests.vue";
import RandomReport from "../views/RandomReport.vue";
import StudentLevelModified from "../views/StudentLevelModified.vue";
import InsideDocument from "../views/InsideDocument.vue";
import PaidPercent from "../views/PaidPercent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/fees",
    name: "StudentFees",
    component: StudentFees,
  },
  {
    path: "/paid-percent",
    name: "PaidPercent",
    component: PaidPercent,
  },
  {
    path: "/inside",
    name: "InsideDocument",
    component: InsideDocument,
  },
  {
    path: "/expenses-requests",
    name: "ExpensesRequests",
    component: ExpensesRequests,
  },
  {
    path: "/old-receipt",
    name: "OldReceipt",
    component: OldReceipt,
  },
  {
    path: "/old-expenses",
    name: "OldExpenses",
    component: OldExpenses,
  },
  {
    path: "/modified",
    name: "StudentLevelModified",
    component: StudentLevelModified,
  },
  {
    path: "/old-fenex",
    name: "OldFenex",
    component: OldFenex,
  },
  {
    path: "/printe",
    name: "PrintExpenses",
    component: PrintExpenses,
    props: true,
  },
  {
    path: "/random-report",
    name: "RandomReport",
    component: RandomReport,
  },
  {
    path: "/level-section",
    name: "SectionLevelStatistics",
    component: SectionLevelStatistics,
  },
  {
    path: "/section-dates",
    name: "SectionCostForDates",
    component: SectionCostForDates,
  },
  {
    path: "/students-uni",
    name: "AllStudentsUniversity",
    component: AllStudentsUniversity,
  },
  {
    path: "/one",
    name: "LevelOneStudents",
    component: LevelOneStudents,
  },
  {
    path: "/student-discount",
    name: "StudentDiscountReport",
    component: StudentDiscountReport,
  },
  {
    path: "/custom",
    name: "CustomTable",
    component: CustomTable,
    props: true,
  },
  {
    path: "/one-details",
    name: "StudentLevelOneDetails",
    component: StudentLevelOneDetails,
    props: true,
  },
  {
    path: "/print",
    name: "PrintReceipt",
    component: PrintReceipt,
    props: true,
  },
  {
    path: "/paid",
    name: "PaidStudent",
    component: PaidStudent,
    props: true,
  },
  {
    path: "/print",
    name: "PrintInvoice",
    component: PrintInvoice,
    props: true,
  },
  {
    path: "/add",
    name: "AddStudent",
    component: AddStudent,
  },
  {
    path: "/transfer",
    name: "StudentTransfer",
    component: StudentTransfer,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/expenses-type",
    name: "ExpensesType",
    component: ExpensesType,
  },
  {
    path: "/payment-report",
    name: "PaymentReport",
    component: PaymentReport,
  },
  {
    path: "/receipt-type",
    name: "ReceiptType",
    component: ReceiptType,
  },
  {
    path: "/accepted",
    name: "AcceptedType",
    component: AcceptedType,
  },
  {
    path: "/details",
    name: "StudentDetails",
    component: StudentDetails,
  },
  {
    path: "/form",
    name: "FormApplication",
    component: FormApplication,
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
  },
  {
    path: "/receipt",
    name: "Receipt",
    component: Receipt,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/sub",
    name: "SubFees",
    component: SubFees,
  },
  {
    path: "/subdetails",
    name: "SubFeeDetails",
    component: SubFeeDetails,
  },
  {
    path: "/cost",
    name: "SectionCost",
    component: SectionCost,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
