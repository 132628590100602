<template>
  <div>
    <div style="text-align: center" class="not-printable">
      <v-btn @click="printReport()" tile color="primary">
        <v-icon left> mdi-printer </v-icon>
        طباعة
      </v-btn>

      <v-btn @click="exportExcel()" tile color="primary" style="margin-right: 10px">
        <v-icon left> mdi-microsoft-excel </v-icon>
        تصدير اكسل
      </v-btn>
    </div>
    <div>
      <v-container class="container" fluid>
        <div>
          <h3 style="text-align: center">من تاريخ {{ dates[0] }} الى تاريخ {{ dates[1] }}</h3>
        </div>
        <table style="width: 100%" class="table-data" border="1">
          <tr>
            <th>التسلسل</th>
            <th>رقم الوصل</th>
            <th>تاريخ الوصل</th>
            <th colspan="2">نوع السند</th>
            <th>من صندوق</th>
            <th>المبلغ</th>
            <th colspan="4">الملاحظات</th>
            <th colspan="2">طبع</th>
          </tr>

          <tr v-for="(item, index) in receipt" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.idReceipt }}</td>
            <td>{{ item.receiptDateFormat }}</td>
            <td colspan="2">{{ item.receiptTypeId == 12 ? "قبض داخلي" : item.typeName }}</td>
            <td>{{ item.employeeName }}</td>
            <td>
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </td>
            <td colspan="4">{{ item.notes }}</td>
            <td colspan="2">
              <v-btn icon @click="printVoucher(item)">
                <v-icon color="primary"> mdi-printer </v-icon>
              </v-btn>
            </td>
          </tr>
        </table>
      </v-container>
    </div>
    <div>
      <h3 style="font-size: 14px">
        المجموع :
        {{
          getTotalMoney(receipt).toLocaleString("en-US", {
            style: "currency",
            currency: "IQD",
          })
        }}
      </h3>
    </div>
    <canvas id="barcode"></canvas>
  </div>
</template>

<script>
const Excel = require("exceljs");
var JsBarcode = require("jsbarcode");
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
export default {
  props: {
    receipt: Array,
    headers: Array,
    showExport: Boolean,
    dates: Array,
  },
  data() {
    return {
      tab: null,
      arrayData: [],
      voucherIndex: 0,
    };
  },
  created() {
    let self = this;

    let internalData = self.receipt.filter((data) => data.isInternal == 1);
    let externalData = self.receipt.filter((data) => data.isInternal == 2);
    let installment = internalData.filter((data) => data.boxId == 1);
    let identity = internalData.filter((data) => data.boxId == 2);
    let form = internalData.filter((data) => data.boxId == 7);
    let correct = internalData.filter((data) => data.boxId == 8);
    let previews = internalData.filter((data) => data.boxId == 5);
    let certificates = internalData.filter((data) => data.boxId == 4);
    let newData = [externalData, installment, identity, form, correct, previews, certificates];
    console.log(newData);
    self.arrayData = newData;
  },
  methods: {
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      let internalData = self.receipt.filter((data) => data.isInternal == 1);
      let externalData = self.receipt.filter((data) => data.isInternal == 2);
      let installment = internalData.filter((data) => data.boxId == 1);
      let identity = internalData.filter((data) => data.boxId == 2);
      let form = internalData.filter((data) => data.boxId == 7);
      let correct = internalData.filter((data) => data.boxId == 8);
      let previews = internalData.filter((data) => data.boxId == 5);
      let certificates = internalData.filter((data) => data.boxId == 4);
      let newData = [externalData, installment, identity, form, correct, previews, certificates];
      console.log(newData);

      newData.forEach((data, index) => {
        let name = self.getSheetName(index);
        const worksheet = workbook.addWorksheet(name);
        worksheet.views = [{ rightToLeft: true }];

        worksheet.columns = [
          { header: "رقم الوصل", key: "receiptNumber", width: 15 },
          { header: "تاريخ الوصل", key: "receiptDate", width: 50 },
          { header: "نوع السند", key: "type", width: 50 },
          { header: "المبلغ", key: "amount", width: 20 },
          { header: "الملاحظات", key: "notes", width: 50 },
        ];

        data.forEach((receipt) => {
          worksheet.addRow({
            receiptNumber: receipt.idReceipt,
            receiptDate: receipt.receiptDateFormat,
            type: receipt.isInternal == 1 ? "قبض داخلي" : receipt.typeName,
            amount: receipt.amount,
            notes: receipt.notes,
          });
        });

        worksheet.eachRow(function (row, rowNumber) {
          row.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "darkTrellis",
            fgColor: { argb: "FFFFFF00" },
            bgColor: { argb: "FF0000FF" },
          };
        });
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `report.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    printVoucher(voucher) {
      let self = this;
      console.log(voucher);
      JsBarcode("#barcode", `${voucher.idReceipt}`, {
        width: 3,
        height: 30,
        displayValue: false,
      });
      console.log(voucher);
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(window);
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      console.log(voucher);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
            }
            .print-padding {
              padding: 5px !important;
            } 
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : ${voucher.idReceipt}</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : ${voucher.receiptDateFormat}</h3>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 90px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-image__image v-image__image--contain" style="background-image: url('${image}'); background-position: center center"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
               
                </div>
                <div data-v-4a284cfa="">
                  <div data-v-4a284cfa="" class="row">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="">جامعة التراث / الحسابات</h3>
                      <h3 data-v-4a284cfa="">سند قبض</h3>
                      <div data-v-4a284cfa="">
                        <img src="${barcodeCanvas.toDataURL()}"/>
                        <div style="display: none">Show this if the rendering fails.</div>
                      </div>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">انا الموقع ادناه</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">امين الصندوق</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">I Certify</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <h3 data-v-4a284cfa="" style="padding: 10px">استلمت من  ة ${voucher.isInternal == 1 ? voucher.fromEmployee : voucher.receiptTypeName} </h3>
                 
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مبلغا وقدره</h3></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">${voucher.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })}</h3>
                      <h3 data-v-4a284cfa="" style="display: inline-block">${numberWriting} فقط لاغير</h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">وذلك عن</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">تسديد ${
                      // voucher.idBox == 1 ? "اقساط" : voucher.idBox == 4 || voucher.idBox == 5 || voucher.idBox == 9 || voucher.idBox == 10 ? voucher.boxName : voucher.boxName.split("صندوق")[1]
                      // this.boxNames.find((box) => box.id == voucher.idBox).name
                      voucher.boxName
                    }</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">For</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">ملاحظات</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.notes}</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Notes</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3>اسم وتوقيع امين الصندوق</h3>
                      </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4" style="padding-bottom: 80px">
                      <h3 data-v-4a284cfa="">اسم وتوقيع الموظف المختص</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          @page { margin: 0.5cm !important; }
          
          .pagebreak {
            clear: both;page-break-after: always;
          }
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableData()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.receipt).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },
    sliceArrayPrint(arrayOfData) {
      let begin = 0;
      let end = 20;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 20);
      console.log(Math.ceil(arrayOfData.length / 20));

      for (let i = 0; i < numberOfIteration; i++) {
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 20;
      }
      return newArray;
    },

    printTableData() {
      let self = this;
      let tableData = "";
      let receiptData = self.receipt;
      let dataPrint = self.sliceArrayPrint(receiptData.reverse());

      for (let i = 0; i < dataPrint.length; i++) {
        tableData += `
        <h4 style="text-align:center">${self.dates[0]} / ${self.dates[1]}</h4>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>تاريخ الوصل</th>
                <th>نوع السند</th>
                <th>من صندوق</th>
                <th>المبلغ</th>
                <th colspan="4">الملاحظات</th>
              </tr>
              ${self.rowTable(dataPrint[i])}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(dataPrint[i]).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
            ${i == dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
            
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        console.log(self.voucherIndex);
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].idReceipt}</td>
                <td>${arrayOfData[j].receiptDateFormat}</td>
                <td>${arrayOfData[j].typeName}</td>
                <td>${arrayOfData[j].employeeName}</td>
                
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
                <td  colspan="4"> ${arrayOfData[j].notes}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
    getSheetName(index) {
      switch (index) {
        case 0:
          return "المقبوضات الخارجية";
        case 1:
          return "الاقساط";
        case 2:
          return "الهويات";
        case 3:
          return "الاستمارات";
        case 4:
          return "صحة الصدور";
        case 5:
          return "السنوات السابقة";
        case 6:
          return "التأييدات والوثائق";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
  padding: 5px !important;
}
</style>
