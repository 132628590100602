<template>
  <div>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة الوصول الفرعية</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchStudent style="display: inline-block" @clicked="onSearch" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="10" :search="search" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.gender ? "انثى" : "ذكر" }}
            </h4>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studyType ? "مسائي" : "صباحي" }}
            </h4>
          </template>
          <template v-slot:[`item.studentLevel`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ item.studentLevel.level | setLevel }}
            </h4>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="selectStudent(item)">
              <v-icon color="primary"> mdi-check-circle </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchStudent from "../components/SearchStudent.vue";
export default {
  components: {
    SearchStudent,
  },
  data: () => ({
    search: "",
    selected: [],
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "القسم", value: "sectionName" },
      { text: "المرحلة", value: "level" },
      { text: "تاريخ الميلاد", value: "dob" },
      { text: "جنس الطالب", value: "gender" },
      {
        text: "نوع الدراسة",
        value: "studyType",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      if (value.length > 0) {
        console.log(value);
        self.students = value;
      } else {
        self.dataMessage = "لايوجد طلبة في القسم";
      }
    },
    addFess() {
      console.log(this.selected);
      if (this.selected.length > 0) {
        this.$store.state.studentPay = this.selected[0];
        this.$router.push("/fees").catch((error) => {});
      } else {
        this.$toasted.error("الرجاء اختيار طالب ");
      }
    },
    selectStudent(student) {
      let self = this;
      self.$store.state.subFeeStudentDetails = student;
      this.$router.push("/subdetails").catch((error) => {});
      console.log(student);
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
