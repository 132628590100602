<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="voucherNumber" outlined label="رقم الوصل" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="studentName" outlined label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="collegeNumber" outlined label="الرقم الجامعي" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="sectionName" outlined label="القسم" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="studentLevel" outlined label="المرحلة" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="voucherDate" outlined label="تاريخ الوصل" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="paymentFor" outlined label="تسديد عن" :rules="studentRequiredFormRules"></v-text-field>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-text-field v-model="notes" outlined label="الملاحظات" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",
      voucherNumber: "",
      sectionName: "",
      studentLevel: "",
      voucherDate: "",
      paymentFor: "",
      notes: "",
      collegeNumber: "",
      amount: "25000",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },
  methods: {
    saveData() {
      let self = this;

      let data = {
        voucherNumber: self.voucherNumber,
        notes: self.notes,
        studentName: self.studentName,
        collegeNumber: self.collegeNumber,
        sectionName: self.sectionName,
        amount: self.amount * 1,
        studentLevel: self.studentLevel,
        voucherDate: self.voucherDate,
        paymentFor: self.paymentFor,
      };

      console.log(data);
      self.show = false;
      this.$emit("clicked", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
