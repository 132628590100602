<template>
  <div>
    <v-container class="container" fluid>
      <v-row>
        <v-col cols="12" align="center" align-self="center" style="height: 100vh; padding: 50px">
          <v-container class="container" fluid>
            <div style="margin-bottom: 20px">
              <v-img :src="require('../assets/logo.png')" class="my-3" contain height="200" />
            </div>
            <div style="margin: auto; width: 500px">
              <v-text-field solo v-model="mail" label="البريد الالكتروني"></v-text-field>
              <v-text-field solo v-model="password" type="password" label="كلمة المرور" @keyup.enter="login()"></v-text-field>

              <v-btn block @click="login" color="primary">
                <v-icon left> mdi-login-variant </v-icon>
                تسجيل دخول
              </v-btn>
            </div>
          </v-container>
        </v-col>
        <!-- <v-col
                cols="8"
                style="background-color: #f5eadb; height: 99vh; padding: 50px"
             >
                <div>
                   <h1 style="font-size: 50px; padding-top: 30%; color: #2cbafc">
                      تطبيق الاساتذة
                   </h1>
                   <h2 style="color: #f9a865">
                      لادخال درجات الطلبة وتصدير السعيات
                   </h2>
                </div>
             </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: "",
    mail: "",
  }),
  methods: {
    login() {
      let self = this;
      // this.$router.push("/");
      if (self.mail && self.password) {
        let credentials = {
          password: self.password,
          mail: self.mail,
        };
        self.$http
          .post(`${self.$store.state.apiUrl}/login`, credentials)
          .then((res) => {
            // localStorage.setItem("employee", JSON.stringify(res.data.token));
            if (self.parseJwt(res.data.token).sectionId == 15) {
              self.$store.state.user = res.data.token;
              self.$store.state.userData = self.parseJwt(res.data.token);
              self.$store.state.hideLogin = true;
              if (self.$store.state.user) {
                if (self.$store.state.userData.roleId != 61) {
                  self.$store.state.items = [
                    { title: "/", text: "الرئيسية", icon: "mdi-home" },
                    {
                      title: "/fees",
                      text: "اقساط الطلبة",
                      icon: "mdi-account-cash",
                    },
                    {
                      title: "/transfer",
                      text: "ترحيل الطلبة",
                      icon: "mdi-account-arrow-up",
                    },
                    {
                      title: "/add",
                      text: "اضافة طالب",
                      icon: "mdi-account-plus",
                    },
                    {
                      title: "/one",
                      text: "الطلبة الجدد",
                      icon: "mdi-account-school",
                    },
                    {
                      title: "/paid",
                      text: "تسديدات الطلبة",
                      icon: "mdi-account-credit-card",
                    },
                    // {
                    //   title: "/sub",
                    //   text: "الوصول الفرعية",
                    //   icon: "mdi-receipt-text",
                    // },
                    {
                      title: "/receipt",
                      text: "الايرادات",
                      icon: "mdi-inbox-arrow-down",
                    },
                    {
                      title: "/expenses",
                      text: "الصرفيات",
                      icon: "mdi-inbox-arrow-up",
                    },
                    {
                      title: "/form",
                      text: "الاستمارات",
                      icon: "mdi-form-select",
                    },

                    { title: "/report", text: "تقارير الايرادات والمصروفات", icon: "mdi-file-chart" },

                    { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
                  ];
                  self.$store.state.subList = [
                    {
                      title: "/inside",
                      text: "المذكرات الداخلية",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/expenses-requests",
                      text: "طلبات الصرفيات",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/student-discount",
                      text: "تقارير التخفيضات",
                      icon: "mdi-chevron-triple-up",
                    },
                    {
                      title: "/expenses-type",
                      text: "تبوبات الصرف",
                      icon: "mdi-chevron-triple-up",
                    },
                    {
                      title: "/receipt-type",
                      text: "تبويبات الايرادات",
                      icon: "mdi-chevron-triple-down",
                    },
                    {
                      title: "/accepted",
                      text: "قنوات القبول",
                      icon: "mdi-backburger",
                    },
                    {
                      title: "/section-dates",
                      text: "تقارير الاقسام حسب التاريخ",
                      icon: "mdi-backburger",
                    },
                    {
                      title: "/level-section",
                      text: "احصائيات الاقسام والمراحل",
                      icon: "mdi-backburger",
                    },
                    {
                      title: "/cost",
                      text: "اقساط الاقسام",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/students-uni",
                      text: "جميع الطلبة",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/old-fenex",
                      text: "بيانات فينيكس",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/old-receipt",
                      text: "ايرادات سابقة",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/old-expenses",
                      text: "مصروفات سابقة",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/random-report",
                      text: "تقرير الهويات والخدمات",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/custom",
                      text: "طباعة جدول",
                      icon: "mdi-cash-100",
                    },
                    {
                      title: "/paid-percent",
                      text: "نسب المدفوعات",
                      icon: "mdi-cash-100",
                    },
                  ];
                } else if (this.$store.state.userData.idEmployee == 223) {
                  {
                    self.$store.state.items = [
                      { title: "/", text: "الرئيسية", icon: "mdi-home" },
                      {
                        title: "/fees",
                        text: "اقساط الطلبة",
                        icon: "mdi-account-cash",
                      },
                      {
                        title: "/transfer",
                        text: "ترحيل الطلبة",
                        icon: "mdi-account-arrow-up",
                      },
                      {
                        title: "/add",
                        text: "اضافة طالب",
                        icon: "mdi-account-plus",
                      },

                      {
                        title: "/one",
                        text: "الطلبة الجدد",
                        icon: "mdi-account-school",
                      },
                      // {
                      //   title: "/sub",
                      //   text: "الوصول الفرعية",
                      //   icon: "mdi-receipt-text",
                      // },
                      {
                        title: "/form",
                        text: "الاستمارات",
                        icon: "mdi-form-select",
                      },
                      { title: "/report", text: "تقارير الايرادات والمصروفات", icon: "mdi-file-chart" },
                      { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
                    ];
                  }
                } else {
                  self.$store.state.items = [
                    { title: "/", text: "الرئيسية", icon: "mdi-home" },
                    {
                      title: "/fees",
                      text: "اقساط الطلبة",
                      icon: "mdi-account-cash",
                    },
                    {
                      title: "/transfer",
                      text: "ترحيل الطلبة",
                      icon: "mdi-account-arrow-up",
                    },
                    {
                      title: "/add",
                      text: "اضافة طالب",
                      icon: "mdi-account-plus",
                    },
                    {
                      title: "/one",
                      text: "الطلبة الجدد",
                      icon: "mdi-account-school",
                    },
                    // {
                    //   title: "/sub",
                    //   text: "الوصول الفرعية",
                    //   icon: "mdi-receipt-text",
                    // },
                    {
                      title: "/form",
                      text: "الاستمارات",
                      icon: "mdi-form-select",
                    },
                    { title: "/payment-report", text: "تقارير الطلبة", icon: "mdi-file-search" },
                  ];
                }
              }
              self.$router.push("/").catch((e) => {});
            } else {
              this.$toasted.error("لا تملك الصلاحيات للدخول الى البرنامج");
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال  اسم المستخدم وكلمة المرور");
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style>
.register:hover {
  cursor: pointer;
}
</style>
