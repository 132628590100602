<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>

          <v-autocomplete v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

          <v-autocomplete v-model="level" :items="levels" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete>

          <v-autocomplete v-model="typeOfPayId" :items="typeOfPay" item-text="name" item-value="id" outlined label="النسبة"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchStudentPaid" v-on:keyup.enter="searchStudentPaid"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    paymentTypes: Array,
  },
  data: () => ({
    searchDialog: false,
    sectionId: "",
    typeOfPayId: "",
    paymentTypeId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    typeOfPay: [
      { name: "25%", id: 1 },
      { name: "50%", id: 2 },
      { name: "75%", id: 3 },
      { name: "100%", id: 4 },
      { name: "25% اقل من", id: 5 },
      { name: "50% اقل من", id: 6 },
      { name: "75% اقل من", id: 7 },
      { name: "100% اقل من", id: 8 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
      { levelName: "جميع المراحل", level: 10 },
    ],
    level: "",
    allSectionInstallments: [],
  }),
  created() {},
  methods: {
    searchStudentPaid() {
      let self = this;
      let query = "";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentPaymentPercent?1=1${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.overlay = false;
          self.searchDialog = false;
          console.log(res.data);
          this.$emit("clicked", { data: res.data, section: self.$store.state.sections.find((se) => se.idSection == self.sectionId), typeOfPayId: self.typeOfPayId });
        })
        .catch((err) => {
          console.log(err);
          self.overlay = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
