<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة عنوان للصفحة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="employeeName" outlined label="اسم الموظف" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="twoDates" outlined label="التاريخ" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-page-layout-header </v-icon>
        </v-btn>
      </template>
      <span>اضافة </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      employeeName: "",
      twoDates: "",
    };
  },
  methods: {
    saveData() {
      let self = this;

      let data = {
        twoDates: self.twoDates,
        employeeName: self.employeeName,
      };

      console.log(data);
      self.show = false;
      this.$emit("clicked", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
