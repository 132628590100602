<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>انشاء تقرير</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete v-model="paymentTypeId" :items="paymentTypes" item-text="name" item-value="id" outlined label="النوع"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="createdBy" :items="employees" item-text="employeeName" item-value="createdBy" outlined label="من صندوق"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="level" :items="levels" item-text="name" item-value="id" outlined label="المرحلة"></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-autocomplete v-model="sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-dialog ref="dialog" v-model="datePickerSearchModel" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" label="اختر التاريخ من - الى" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerSearchModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
    employees: Array,
  },
  data: () => ({
    searchDialog: false,
    paymentTypeId: "",
    createdBy: "",
    level: "",
    sectionId: "",
    datePickerSearchModel: false,
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    paymentTypes: [
      { name: "اقساط", id: 1 },
      { name: "التأييدات", id: 2 },
      { name: "الهويات", id: 3 },
      { name: "الاستمارات", id: 4 },
      { name: "صحة الصدور", id: 5 },
      { name: "السنوات السابقة", id: 6 },
      { name: "اقساط + صحة صدور", id: 7 },
      { name: "اقساط حجز مقعد", id: 13 },
      { name: "هويات حجز معقد", id: 14 },
      { name: "تدوير المبلغ", id: 15 },
      { name: "الخدمات الجامعية", id: 11 },
      { name: "التدريب الصيفي لطب الاسنان", id: 11 },
      { name: "الجميع", id: 8 },
    ],
    levels: [
      { name: "المرحلة الاولى", id: 1 },
      { name: "المرحلة الثانية", id: 2 },
      { name: "المرحلة الثالثة", id: 3 },
      { name: "المرحلة الرابعة", id: 4 },
      { name: "المرحلة الخامسة", id: 5 },
      { name: "خريج", id: 10 },
      { name: "جميع المراحل", id: 11 },
      { name: "جميع المراحل عدا الاول", id: 12 },
    ],
    overlay: false,
    dates: [],
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;

      let query = "data=1";

      if (self.dates.length > 0) {
        query += `&dates=${JSON.stringify(self.dates)}`;
      }
      if (self.paymentTypeId) {
        query += `&paymentTypeId=${JSON.stringify(self.paymentTypeId)}`;
      }
      if (self.createdBy) {
        query += `&createdBy=${JSON.stringify(self.createdBy)}`;
      }

      if (self.sectionId) {
        query += `&sectionId=${JSON.stringify(self.sectionId)}`;
      }

      if (self.level) {
        query += `&studentLevel=${JSON.stringify(self.level)}`;
      }

      if (self.paymentTypeId != 4) {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/searchPayment?${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            let reportData = {
              dates: self.dates,
              data: res.data,
              isFormApplication: false,
              hasData: res.data.length > 0 ? true : false,
            };
            this.$emit("clicked", reportData);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
        this.searchDialog = false;
      } else {
        let queryForm = "data=1";

        if (self.createdBy) {
          queryForm += `&createdBy=${JSON.stringify(self.createdBy)}`;
        }

        if (self.dates.length > 0) {
          queryForm += `&dates=${JSON.stringify(self.dates)}`;
        }

        console.log(query);

        self.$http
          .get(`${self.$store.state.apiUrlFees}/filterFormNumber?${queryForm}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            let reportData = {
              dates: self.dates,
              data: res.data,
              isFormApplication: true,
              hasData: res.data.length > 0 ? true : false,
            };
            this.$emit("clicked", reportData);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
        this.searchDialog = false;
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.length > 0 ? this.dates.join(" - ") : "تاريخ من - الى";
    },
  },
};
</script>

<style lang="scss" scoped></style>
