<template>
  <div>
    <v-dialog v-model="show" persistent fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>المستندات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="4" v-for="(photo, index) in profilePhotos" :key="index">
              <img crossOrigin="anonymous" :src="`${$store.state.apiUrlFees}/${photo.photoPath}`" @click="fullScreenImage($event)" alt="" width="33%" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="show = false" v-on:keyup.enter="show = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn text small @click="show = true"> عرض المستندات </v-btn>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
export default {
  props: {
    profilePhotos: Array,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
    };
  },
  created() {
    console.log(this.profilePhotos);
  },
  methods: {
    fullScreenImage(e) {
      const doc = new jsPDF();

      const getBase64StringFromDataURL = (dataURL) => dataURL.replace("data:", "").replace(/^.+,/, "");

      const canvas = document.createElement("canvas");

      canvas.width = e.target.naturalWidth;
      canvas.height = e.target.naturalHeight;

      canvas.getContext("2d").drawImage(e.target, 0, 0);

      const dataURL = canvas.toDataURL();

      const base64 = getBase64StringFromDataURL(dataURL);

      doc.addImage(base64, 0, 0, 210, 300);
      doc.save("document.pdf");

      canvas.remove();

      // let imageWindow = window.open(
      //   "",
      //   "",
      //   "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0"
      // );

      // imageWindow.document.write(`<!DOCTYPE html>
      //             <html>
      //             <head></head>
      //             <body></body> <img src="${this.$store.state.apiUrl}/${image.imagePath}"  style="margin-top: 10px;"></html>`);

      // setTimeout(() => {
      //   imageWindow.document.close();
      //   imageWindow.focus();
      //   imageWindow.print();
      //   imageWindow.close();
      // }, 500);
      // //  setTimeout(function () {
      // //  }, 500);
    },
    saveData() {
      let self = this;
      console.log(self.collegeNumber, self.yearStudyId);

      if (self.notes) {
        let data = {
          collegeNumber: self.collegeNumber,
          yearStudyId: self.yearStudyId,
          notes: self.notes,
        };

        this.$http
          .post(`${this.$store.state.apiUrlRegistration}/addLevelNote`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("لا يمكن ترك الحقل فارغ");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
